<template>
  <div>
    <TabTitle class="mb-4" icon="fa-plus" action-position="right">
      <template>{{ $route.params.roleId ? 'Edit' : 'Create' }} Role</template>
      <template #sub-title>{{ $route.params.roleId ? 'Edit' : 'Create' }} a new role to this company.</template>
      <template #actions>
        <router-link class="btn btn-default" :to="{ path: `/company/roles` }">Back to roles <i class="fa fa-arrow-right ml-1"></i></router-link>
      </template>
    </TabTitle>
    <Spinner v-if="loading" />
    <div v-else class="block-content">
      <div v-if="error" class="alert alert-danger">{{ error }}</div>

      <RoleForm v-model="form" :error="validationErrors" />

      <div class="text-right pb-4">
        <p v-if="form.permissionIds?.length === 0" class="text-danger"><i class="fa fa-times-circle fa-fw"></i>Please select a permission</p>
        <button type="submit" class="btn btn-primary" :disabled="loading || form.permissionIds?.length === 0" @click="onClickSubmit">
          <span v-if="loading"><i class="fa fa-spin fa-spinner mr-1"></i> {{ $route.params.roleId ? 'Updating' : 'Creating' }}...</span>
          <span v-else><i class="fa fa-check-circle mr-1"></i> Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import RoleForm from '@/components/forms/RoleForm';
import TabTitle from '@/components/base/TabTitle';
import Spinner from '@/components/SpinnerLogo';

export default {
  name: 'CompanyRolesManage',
  components: {
    RoleForm,
    Spinner,
    TabTitle
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      role: 'role/role',
      loadingAction: 'role/loadingAction',
      errorAction: 'role/errorAction',
      validationErrors: 'role/validationErrors'
    }),
    loading() {
      return this.loadingAction.create || this.loadingAction.update || this.loadingAction.get;
    },
    error() {
      return this.errorAction.create || this.errorAction.update;
    }
  },
  async mounted() {
    if (this.$route.params.roleId) {
      await this.getRole({ id: this.$route.params.roleId });

      this.form = { ...this.role };
    } else {
      this.form = {};
    }
  },
  methods: {
    ...mapActions({
      getRole: 'role/get',
      createRole: 'role/create',
      updateRole: 'role/update'
    }),
    onClickSubmit() {
      if (this.$route.params.roleId) {
        this.onUpdateRole();
      } else {
        this.onCreateRole();
      }
    },
    async onCreateRole() {
      const response = await this.createRole({ data: this.form });

      if (response._id) {
        this.$toasted.success('Role created successfully!', { position: 'bottom-right', duration: 3000 });
        this.$router.push({ name: 'company-roles-list', params: { id: this.$auth.companyId } });
      } else {
        this.$toasted.error('Role was not created successfully.', { position: 'bottom-right', duration: 3000 });
      }
    },
    async onUpdateRole() {
      const response = await this.updateRole({ id: this.$route.params.roleId, data: this.form });

      if (response._id) {
        this.$toasted.success('Role updated successfully!', { position: 'bottom-right', duration: 3000 });
        this.$router.push({ name: 'company-roles-list', params: { id: this.$auth.companyId } });
      } else {
        this.$toasted.error('Role was not updated successfully.', { position: 'bottom-right', duration: 3000 });
      }
    }
  }
};
</script>
